import { render, staticRenderFns } from "./PickupHistory.vue?vue&type=template&id=05561c57&scoped=true"
import script from "./PickupHistory.vue?vue&type=script&lang=js"
export * from "./PickupHistory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05561c57",
  null
  
)

export default component.exports